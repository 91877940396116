import Vuex from 'vuex';
import config from '../config';
import load from '../load';
const api = config.API_URL;
const url = process.env.VUE_APP_BASE_URL;

export default new Vuex.Store({
  state: {
    date: "",
    orders: [],
    users: [],
    cars: [],
    clients: [],
    usersMap: new Map,
    companies: [],
    orderFilters: (()=>{
      try{
        var res = JSON.parse(localStorage.getItem('orderFilters'));
        if(res)return res;
        return ["Брянск", '', ''];
      }catch(_){
        return ["Брянск", '', ''];
      }})(),
    // filterText1: "Брянск",
    // filterText2: "",
    // filterText3: "",
    userInfo: (()=>{
      try{
        return JSON.parse(localStorage.getItem('userInfo'));
      }catch(_){
        return null;
      }})(),
    car: null,
    client: null,
  },
  mutations: {
    setDate(state, date) { state.date = date },
    setOrders(state, orders) {
      state.orders = orders;
      state.ordersMap = new Map(orders.map(o=>[o.id, o]));
    },
    setArchive(state, archive) {
      state.archive = archive;
      state.archiveMap = new Map(archive.map(o=>[o.id, o]));
    },
    setUsers(state, users) {
      state.users = users;
      state.usersMap = new Map(users.map(u=>[u.id, u]));
    },
    setCars(state, cars) {
      state.cars = cars;
    },
    setClient(state, client){
      state.client = client;
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setCompanies(state, companies) { state.companies = companies },
    setUserInfo(state, info) {
      state.userInfo = info;
      localStorage.setItem('userInfo', JSON.stringify(info));
    },
    setFilters(state, filters_arr) {
      console.log("фильтра: ", filters_arr);
      state.orderFilters = filters_arr;
      localStorage.setItem('orderFilters', JSON.stringify(filters_arr));
    },
    setCar(state, car){state.car = car;},
  },
  actions: {
    async getAllOrders(context) {
      try{
        var data =  await load.get('orders');
        context.commit('setOrders', data.sort((a,b)=>b.id-a.id));
      }catch(_){
        context.commit('setOrders', []);
      }
    },
    async getAllArchive(context) {
      try{
        var data =  await load.get('archive');
        context.commit('setArchive', data.sort((a,b)=>b.id-a.id));
      }catch(_){
        context.commit('setArchive', []);
      }
    },
    async getAllUsers(context) {
      try{
        var data =  await load.get('users');
        context.commit('setUsers', data);
      }catch(_){
        context.commit('setUsers', []);
      }
    },
    async getAllCars(context) {
      try{
        var data =  await load.get('cars');
        context.commit('setCars', data);
      }catch(_){
        context.commit('setCars', []);
      }
    },
    async getAllClients(context) {
      try{
        var data =  await load.get('clients');
        context.commit('setClients', data);
      }catch(_){
        context.commit('setClients', []);
      }
    },
    async getAllCompanies(context) {
      try {
        var response = await(await fetch(api + 'companies', {
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.state.userInfo?.jwt}`
          }
        })).json();
        console.log("Ответ: ", response);
        if(response.success)
          context.commit('setCompanies', response.data);
        else
          throw response;
      } catch (error) { console.log(error) };
    },
    async getCar(context, id){
      try{
        let car =  await load.get(`car/${id}`);
        context.commit('setCar', car);
      }catch(_){
        context.commit('setCar', null);
      }
    },
    async getClient(context, id){
      try{
        let client =  await load.get(`client/${id}`);
        context.commit('setClient', client);
      }catch(_){
        context.commit('setClient', null);
      }
    },
    async removeCar(context, id){
      await load.delete(`car/${id}`);
      context.commit('setCar', null);
    },
    setSalary(context, data){
      return load.put('salary', data);
    },
  }
})
