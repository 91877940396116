const routes = [
    {
        path: '/*',
        name: 'not-found',
        component: () => import('../views/404.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('../views/Orders.vue')
    },
    {
        path: '/orders_arch',
        name: 'orders_arch',
        component: () => import('../views/Orders_arch.vue')
    },
    {
        path: '/info/:id',
        name: 'info',
        component: () => import('../views/Info.vue')
    },
    {
        path: '/loggin',
        name: 'loggin',
        component: () => import('../views/Loggin.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/Users.vue')
    },
    {
        path: '/user/:id',
        name: 'userInfo',
        component: () => import('../views/UserInfo.vue')
    },
    {
        path: '/add-courier',
        name: 'addCourier',
        component: () => import('../views/AddCourierForm.vue')
    },
    {
        path: '/add-manager',
        name: 'addManager',
        component: () => import('../views/AddManagerForm.vue')
    },
    {
        path: '/add-admin',
        name: 'addAdmin',
        component: () => import('../views/AddAdminForm.vue')
    },
    {
        path: '/add-order',
        name: 'addOrder',
        component: () => import('../views/AddOrder.vue')
    },
    {
        path: '/report',
        name: 'report',
        component: () => import('../views/Report.vue')
    },
    {
        path: '/cars',
        name: 'cars',
        component: ()=> import('../views/Cars.vue')
    },
    {
        path: '/car/:id',
        name: 'carInfo',
        component: ()=> import('../views/CarInfo.vue')
    },
    {
        path: '/add-car',
        name: 'addCar',
        component: ()=> import('../views/AddCar.vue')
    },
    {
        path: '/add-client',
        name: 'addClient',
        component: ()=> import('../views/AddClient.vue')
    },
    {
        path: '/clients',
        name: 'clients',
        component: ()=> import('../views/Clients.vue')
    },
    {
        path: '/client/:id',
        name: 'clientInfo',
        component: ()=> import('../views/ClientInfo.vue')
    },
    {
        path: '/car-report',
        name: 'carReport',
        component: ()=> import('../views/CarReport.vue')
    },/*
    {
        path: '/car-report/:car/:month',
        name: 'carReport',
        component: ()=> import('../views/CarReport.vue')
    },*/
];

export default routes;
