<template>
  <section>
    <div class="pc-none mobile-menu">
      <div class="align-items-center d-flex">
        <img
        @click.prevent="$router.push('/orders')"
        src="./assets/image/logo_flybee.png"
        class="mini-logo"
        style="cursor: pointer; margin-left: -10px"
        alt=""
      />
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path == '/orders'">Заказы</p>
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path == '/orders_arch'">Архив</p>
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path.includes('/info')">Заказ</p>
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path == '/users'">Сотрудники</p>
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path == '/add-courier'">Добавить курьера</p>
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path == '/add-admin'">Добавить админа</p>
      <p class="ml-2" style="font-weight: 600; font-size: 20px;" v-if="$route.path == '/add-manager'">Добавить кладовщика</p>

      </div>

      <button
        @click="menuOpen = !menuOpen"
        class="btn btn-warning"
        style="height: fit-content; display: none"
      >
        <img src="./assets/image/bars.svg" style="width: 20px" alt="" />
      </button>
    </div>
    <div class="container-fluid mobile-padding" @click="menuOpen = false">
      <div class="row">
        <div
          v-if="$store.state.userInfo"
          class="col-sm-3 col-md-3 col-xl-2 text-whit mobile-burger"
          style="box-shadow: 0px 0px 77px 3px rgba(0, 0, 0, 0.10); z-index: 10;"
          :class="{ show: menuOpen }"
        >
          <div class="inner-menu">
            <img
              src="./assets/image/logo_flybee.png"
              class="img-fluid mobile-logo"
              alt=""
            />
            <ul style="flex-flow: column" class="nav">
              <li class="nav-item">
                <a
                  v-if="$store?.state?.userInfo?.data?.role == 'admin'"
                  :class="{ active: $route.path == '/dashboard' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/dashboard"
                  @click.prevent="$router.push('/dashboard')"
                  >
                  <div class="pc-link-menu">
                    <img src="./assets/image/menu/main.svg" alt="">
                    Главная
                  </div>
                  </a>
              </li>

              <li class="nav-item">
                <a
                  :class="{ active: $route.path == '/orders' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2 btn btn-success nav-link mx-auto my-2 pc-none"
                  href="/orders"
                  style=""
                  @click.prevent="$router.push('/orders')"
                  >
                  <div class="pc-link-menu">
                  <img :class="{ danone: $route.path == '/orders' }" src="./assets/image/menu/orders.svg" alt="">
                  <img :class="{ danone: $route.path != '/orders' }" src="./assets/image/menu/active/orders.svg" alt="">
                  Заказы</div></a
                >
              </li>

              <li class="nav-item">
                <a
                  :class="{ active: $route.path == '/orders_arch' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2 btn btn-secondary nav-link mx-auto my-2 pc-none"
                  href="/orders_arch"
                  @click.prevent="$router.push('/orders_arch')"
                  >
                  <div class="pc-link-menu">
                  <img :class="{ danone: $route.path == '/orders_arch' }" src="./assets/image/menu/archive.svg" alt="">
                  <img :class="{ danone: $route.path != '/orders_arch' }" src="./assets/image/menu/active/archive.svg" alt="">
                  Архив</div></a
                >
              </li>

              <li class="nav-item">
                <a
                  :class="{ active: $route.path == '/orders' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2 mobile-none"
                  href="/orders"
                  style=""
                  @click.prevent="$router.push('/orders')"
                  >
                  <div class="pc-link-menu">
                  <img :class="{ danone: $route.path == '/orders' }" src="./assets/image/menu/orders.svg" alt="">
                  <img :class="{ danone: $route.path != '/orders' }" src="./assets/image/menu/active/orders.svg" alt="">
                  Заказы</div></a
                >
              </li>

              <li class="nav-item">
                <a
                  :class="{ active: $route.path == '/orders_arch' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2 mobile-none"
                  href="/orders_arch"
                  @click.prevent="$router.push('/orders_arch')"
                  >
                  <div class="pc-link-menu">
                  <img :class="{ danone: $route.path == '/orders_arch' }" src="./assets/image/menu/archive.svg" alt="">
                  <img :class="{ danone: $route.path != '/orders_arch' }" src="./assets/image/menu/active/archive.svg" alt="">
                  Архив</div></a
                >
              </li>

              <li
                v-if="$store?.state?.userInfo?.data?.role == 'admin'"
                class="nav-item"
              >
                <a
                  :class="{ active: $route.path == '/users' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/users"
                  @click.prevent="$router.push('/users')"
                  >
                  <div class="pc-link-menu">
                  <img :class="{ danone: $route.path == '/users' }" src="./assets/image/menu/users.svg" alt="">
                  <img :class="{ danone: $route.path != '/users' }" src="./assets/image/menu/active/users.svg" alt="">
                  Пользователи</div></a
                >
              </li>
              <li
                v-if="$store?.state?.userInfo?.data?.role == 'admin'"
                class="nav-item"
              >
                <a
                  :class="{ active: $route.path == '/report' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/report"
                  @click.prevent="$router.push('/report')"
                  >
                  <div class="pc-link-menu">
                  <img :class="{ danone: $route.path == '/report' }" src="./assets/image/menu/report1.svg" alt="">
                  <img :class="{ danone: $route.path != '/report' }" src="./assets/image/menu/report1.svg" alt="">
                  Отчёт</div></a
                >
              </li>
              <li
                v-if="$store?.state?.userInfo?.data?.role == 'admin'"
                class="nav-item"
              >
                <a
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/cars"
                  @click.prevent="$router.push('/cars')"
                >
                  <div class="pc-link-menu">
                    <img :class="{ danone: $route.path == '/cars' }" src="./assets/image/menu/menu-auto.svg" alt="">
                    <img :class="{ danone: $route.path != '/cars' }" src="./assets/image/menu/active/menu-auto.svg" alt="">
                    Автомобили
                  </div>
                </a>
              </li>
              <li
                v-if="$store?.state?.userInfo?.data?.role == 'admin'"
                class="nav-item"
              >
                <a
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/clients"
                  @click.prevent="$router.push('/clients')"
                >
                  <div class="pc-link-menu">
                    <img :class="{ danone: $route.path == '/clients' }" src="./assets/image/menu/menu-auto.svg" alt="">
                    <img :class="{ danone: $route.path != '/clients' }" src="./assets/image/menu/active/menu-auto.svg" alt="">
                    Клиенты
                  </div>
                </a>
              </li>
              <li
                v-if="$store?.state?.userInfo?.data?.role == 'admin'"
                class="nav-item"
              >
                <a
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/car-report"
                  @click.prevent="$router.push('/car-report')"
                >
                  <div class="pc-link-menu">
                    <img :class="{ danone: $route.path == '/clients' }" src="./assets/image/menu/menu-auto.svg" alt="">
                    <img :class="{ danone: $route.path != '/clients' }" src="./assets/image/menu/active/menu-auto.svg" alt="">
                    По машинам
                  </div>
                </a>
              </li>
              <li
                v-if="$store.state.userInfo"
                class="nav-item"
              >
                <a
                  :class="{ active: $route.path == '/users' }"
                  class="nav-link bs-a-ul-zak mx-auto my-2"
                  href="/users"
                  @click="logout"
                  >
                  <div class="pc-link-menu">
                  <img src="./assets/image/menu/exit.svg" alt="">
                  Выйти</div></a
                >
              </li>
              <!-- <li v-if="$store.state.userInfo">
                <router-link
                  @click="logout"
                  to="/dashboard"
                  class="btn btn-secondary w-50 nav-link mx-auto my-2"
                >
                <img src="./assets/image/menu/main.svg" alt="">
                  Выйти
                </router-link>
              </li> -->
              <li
              v-if="!$store.state.userInfo"
              class="nav-item"
            >
              <a
                :class="{ active: $route.path == '/loggin' }"
                class="nav-link bs-a-ul-zak mx-auto my-2"
                href="/loggin"
                @click="logout"
                >
                <div class="pc-link-menu">
                <img src="./assets/image/menu/exit.svg" alt="">
                Войти</div></a
              >
            </li>
              <!-- <li v-if="!$store.state.userInfo">
                <a
                  href="/loggin"
                  class="btn btn-login btn-lg btn-primary w-50 nav-link mx-auto my-2"
                  >Войти</a
                >
              </li> -->
            </ul>
            <div class="pc-none" style="position: absolute; bottom: 25px; width: 90%;">
              <div style="display: flex; justify-content: space-between; width: 100%;">
                <p style="font-weight: 200;">Версия 0.2</p>
                <p>Сегодня: {{ date }}</p>
              </div>
            </div>
          </div>
          <div class="mobile-none" style="position: absolute; bottom: 25px; width: 90%;">
            <div style="display: flex; justify-content: space-between; width: 100%;">
              <p style="font-weight: 200;">Версия 0.2</p>
              <p>Сегодня: {{ date }}</p>
            </div>
          </div>
        </div>

        <router-view v-if="isRouterAlive" />

        <div v-if="$store.state.userInfo" class="pc-none mobile-sub-menu">
          <li class="nav-item">
            <a
              :class="{ active: $route.path == '/orders' }"
              class="nav-link bs-a-ul-zak mx-auto my-2"
              href="/orders"
              style=""
              @click.prevent="$router.push('/orders')"
              >
              <div class="pc-link-menu">
              <img :class="{ danone: $route.path == '/orders' }" src="./assets/image/menu/orders.svg" alt="">
              <img :class="{ danone: $route.path != '/orders' }" src="./assets/image/menu/active/orders.svg" alt="">
              </div></a
            >
          </li>

          <li class="nav-item">
            <a
              :class="{ active: $route.path == '/orders_arch' }"
              class="nav-link bs-a-ul-zak mx-auto my-2"
              href="/orders_arch"
              @click.prevent="$router.push('/orders_arch')"
              >
              <div class="pc-link-menu">
              <img :class="{ danone: $route.path == '/orders_arch' }" src="./assets/image/menu/archive.svg" alt="">
              <img :class="{ danone: $route.path != '/orders_arch' }" src="./assets/image/menu/active/archive.svg" alt="">
              </div></a
            >
          </li>

          <li
            v-if="$store?.state?.userInfo?.data?.role == 'admin'"
            class="nav-item"
          >
            <a
              :class="{ active: $route.path == '/users' }"
              class="nav-link bs-a-ul-zak mx-auto my-2"
              href="/users"
              @click.prevent="$router.push('/users')"
              >
              <div class="pc-link-menu">
              <img :class="{ danone: $route.path == '/users' }" src="./assets/image/menu/users.svg" alt="">
              <img :class="{ danone: $route.path != '/users' }" src="./assets/image/menu/active/users.svg" alt="">
              </div></a
            >
          </li>
          <li
            v-if="$store.state.userInfo"
            class="nav-item"
          >
            <a
              :class="{ active: $route.path == '/users' }"
              class="nav-link bs-a-ul-zak mx-auto my-2"
              href="/users"
              @click="logout"
              >
              <div class="pc-link-menu">
              <img src="./assets/image/menu/exit.svg" alt="">
              </div></a
            >
          </li>
          <li
          v-if="!$store.state.userInfo"
          class="nav-item"
        >
          <a
            :class="{ active: $route.path == '/loggin' }"
            class="nav-link bs-a-ul-zak mx-auto my-2"
            href="/loggin"
            @click="logout"
            >
            <div class="pc-link-menu">
            <img src="./assets/image/menu/exit.svg" alt="">
            </div></a
          >
        </li>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import load from './load';
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data: () => ({
    date: "",
    isRouterAlive: true,
    menuOpen: false,
    load: load,
  }),
  methods: {
    async logout() {
      await this.$store.commit("setUserInfo", null);
      localStorage.removeItem("orderFilters");
      //await this.$forceUpdate();
      //this.$router.replace(this.$router.currentRoute.value.fullPath);
    },
  },

  reload() {
    this.isRouterAlive = false;
    this.$nextTick(function () {
      this.isRouterAlive = true;
      this.$message({
        message: "reloading",
        type: "success",
      });
    });
  },

  mounted() {
    window.DEBUG_MAIN_API = this;
    this.date = new Date().toLocaleDateString().replace(/\./g, "-");

    //this.date = moment(this.date).format("DD/MM/YYYY");

    //this.getDateNow();
  },
};
</script>

<style>
@font-face {
  font-display: swap;
  src: url("./assets/fonts/roboto/Robotobold.woff") format("woff"),
    url("./assets/fonts/roboto/Robotobold.ttf") format("ttf");
  font-family: "roboto";
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-display: swap;
  src: url("./assets/fonts/roboto/Roboto.woff") format("woff"),
    url("./assets/fonts/roboto/Roboto.ttf") format("ttf");
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-display: swap;
  src: url("./assets/fonts/roboto/Robotoblack.woff") format("woff"),
    url("./assets/fonts/roboto/Robotoblack.ttf") format("ttf");
  font-family: "roboto";
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  src: url("./assets/fonts/roboto/Robotolight.woff") format("woff"),
    url("./assets/fonts/roboto/Robotolight.ttf") format("ttf");
  font-family: "roboto";
  font-style: normal;
  font-weight: 300;
}


* {
  font-family: "roboto" !important;
}
#bottom-pagination{
  display: flex;
  align-items: center;
  margin-top: 30px;
}
#bottom-pagination > .wrap-pag{
  margin: 0 10px;
}
#bottom-pagination > .wrap-pag > a{
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 5px;
  cursor: pointer;
}

.mobile-sub-menu{
  filter: drop-shadow(0px 0px 20px rgba(202, 158, 45, 0.25));
  background: #fff;
  padding: 0px 50px 0px 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.status-new {
  background: rgba(40, 167, 69, 0.20)!important;
}
.status-assembly, .status-ready, .status-selected {
background-color: #ffffff!important;
}
.status-taked{
  background: rgba(0, 123, 255, 0.20)!important;
}
.status-deliverMoney, .status-completed {
  background-color: #bdbbf7!important;
}
.status-delivered {
  background-color: rgb(175, 216, 184)!important;
}
.status-archive {
  background-color: rgb(211, 211, 211)!important;
}
.status-canceled, .status-courierForСancel, .status-courierStartСancel, .status-managerForСancel, .status-managerStartСancel {
  background-color: rgb(255, 204, 204)!important;
}
.status-deliverMoney > a > p, .status-completed > a > p {
  color: #fff!important;
}
.status-new > a > p {
  color: #000;
}

.input-add-user{
  width: 400px;
  max-width: 90vw;
  border: none;
  padding: 10px 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #000;
  outline: none!important;
}
.table-striped-special{
  width: 100%;
  margin: 0 15px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.10);
}
.table-striped-special td{
  word-break: break-word;
  vertical-align: middle;
  border-top: 1px solid #FFBD3D;
}
.table-striped-special th{
  vertical-align: middle;
  border-top: transparent;
  border-bottom: 1px solid #FFBD3D;
}
.table-striped-special tbody tr:nth-of-type(2n+1) {
  background: #F5FFFE;
}
.table-striped-special thead th {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #FFBD3D;
}
.table-striped-special tbody td {
  font-size: 14px;
  line-height: normal;
}
.cloud-table > p {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.10);
  margin: 0;
  line-height: 1;
  padding: 10px;
  text-align: center;
}
.danone{
  display: none!important;
}
.nav-item {
  display: flex;
}
.bs-height-img {
  max-height: 1000px;
}
.bs-p-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #272727;
}
.bs-p-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
  /* margin-left: 15%; */
}
.bs-input-log {
  width: 500px;
  padding: 15px 30px;
  background: #ffffff;
  border: none;
  border-bottom: 2px solid rgba(63, 63, 63, 0.79);
  box-sizing: border-box;
  border-radius: 0px;
  font-size: 20px;
}
.bs-p-danger {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #ff5c5c;
  text-align: center;
}
.bs-a-ul-zak {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  align-items: center;
  width: 90%;
}
.pc-link-menu {
  display: flex;
  align-items: center;
  color: #000!important;
}
.pc-link-menu > img{
  margin-right: 30px;
  width: 40px;
}

@media (max-width: 1440px) {
  .bs-a-ul-zak {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}

.bs-button-log {
  background: #ffbd3d;
  border: none;
  width: 200px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 40px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  transition: 0.5s ease;
}
.bs-button-log:hover {
  transform: scale(1.1);
}
.active {
  text-decoration: underline #ffbd3d;
}
.bs-a-filtr-zak {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: #272727;
}
.bs-input-search-zak {
  width: 465px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(63, 63, 63, 0.79);
  box-sizing: border-box;
  border-radius: 7px;
  padding-left: 20px;
}
.bs-button-search-zak {
  width: 70px;
  height: 45px;
  background: #ffbd3d;
  border: 1px solid rgba(63, 63, 63, 0.79);
  box-sizing: border-box;
  border-radius: 7px;
  margin-left: -3%;
  background-image: url("./assets/image/iconsearch.png");
  background-repeat: no-repeat;
  background-position: center;
}
.bs-nomer-zak {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  background: #ffffff;
  border: 1px solid rgba(63, 63, 63, 0.79);
  box-shadow: 5px 5px 15px #00000040;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.5s ease;
}
.bs-nomer-zak:hover {
  text-decoration: none;
}
.bs-p-zak {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
  margin: 8px 0 !important;
  text-decoration: underline 1px transparent !important;
}
.bs-left-zak {
  margin-left: auto;
  width: 35px;
  height: 35px;
}
.bs-list-zak {
  overflow: auto;
}
.bs-a-filtr-zak:hover {
  cursor: pointer;
}
.bs-filtr-zak {
  position: absolute;
  width: 337px;
  height: 516px;
  left: 1103px;
  top: 234px;
  /* display: none; */
}
.bs-border-hist {
  background: #ffffff;
  /*border: 4px solid #ffbd3d;*/

  box-sizing: border-box;
  border-radius: 10px;
  background: #FFF;
  padding: 30px;
  box-shadow: 0px 0px 23px 3px rgba(0, 0, 0, 0.15);
}
.bs-border-hist::-webkit-scrollbar {
  width: 0px;
}
.mobile-buttons-status{
  border-radius: 10px;
  background: #FFF;
  padding: 30px;
  box-shadow: 0px 0px 23px 3px rgba(0, 0, 0, 0.15);
  margin-left: 30px;
}
.bs-padding-hist {
  padding-top: 20px;
  padding-left: 25px;
  padding-bottom: 20px;
}
.bs-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.bs-input-zakaz {
  width: 260px;
  height: 55px;
  background: #ffffff;
  border: 1px solid rgba(63, 63, 63, 0.79);
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 30px;
}
.bs-labe-zakaz {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
  margin-bottom: 25px;
}
.bs-okey-button {
  background-color: #ffbd3d;
  border: 1px solid rgba(63, 63, 63, 0.5);
  box-sizing: border-box;
  border-radius: 7px;
  width: 200px;
  font-weight: 600;
  height: 45px;
  color: #fff;
  font-size: 20px;
}
.bs-success-button {
  background-color: green;
  border: 1px solid rgba(63, 63, 63, 0.5);
  box-sizing: border-box;
  border-radius: 7px;
  width: 200px;
  height: 45px;
  color: #fff;
  font-weight: 600;
  margin-left: 30px;
  font-size: 20px;
}
.bs-nope-button {
  width: 200px;
  height: 45px;
  background-color: rgba(255, 92, 81, 0.7);
  border: 1px solid rgba(63, 63, 63, 0.5);
  box-sizing: border-box;
  border-radius: 7px;
  color: #fff;
  font-weight: 600;
  margin: 0px 30px;
  font-size: 20px;
}
.bs-input-dop-zakaz {
  width: 260px;
  height: 220px;

  background: #ffffff;
  border: 1px solid rgba(63, 63, 63, 0.79);
  box-sizing: border-box;
  border-radius: 7px;
}
.bs-back-button {
  width: 200px;
  height: 45px;
  background-color: #343a40;
  border: 1px solid rgba(63, 63, 63, 0.5);
  box-sizing: border-box;
  border-radius: 7px;
  font-weight: 600;
  color: #ffffff;
  font-size: 20px;
}
.bs-m-t-60 {
  margin-top: 60px;
}
.bs-filtr-zak {
  position: absolute;
  width: 340px;
  height: fit-content;
  top: 40px;
  bottom: 0;
  left: 15px;
  right: 0;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  z-index: 999;
}
.bs-close {
  position: absolute;
  right: 2%;
  top: 8%;
  border: none;
  background-color: #fff;
}
.bs-filtr-zak {
  padding: 40px 0px 40px 50px;
}
.bs-p-zagl {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}
.bs-list-filtr li {
  margin: 2% 0%;
  display: flex;
  align-items: center;
}
.bs-list-filtr li label {
  margin-left: 10px;
}
.bs-prim-filtr {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 10px;

  color: #272727;
  margin-top: 5%;
}
.bs-clear-filtr {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-top: 5%;
}
.bs-img-ring {
  width: 28px;
  height: 28px;
}
/* #orders {
  display: none;
} */
/*#inspekt-order
{
    display: none;
}*/
/* #history-orders {
  display: none;
} */
@media (max-width: 1680px) {
  .bs-height-img {
    max-height: 100vh;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.vue__time-picker .dropdown ul li {
  padding: 0.5em 0 !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker .dropdown {
  height: 10em !important;
}
.pc-none {
  display: none;
}
.mobile-none {
  display: unset;
}
.inner-menu {
  margin-top: 20px;
}
.new-orders-title {
  font-size: 25px;
  margin: 20px 0;
  font-weight: 600;
}
.mobile-burger{
  height: 100vh; 
  box-shadow: 0px 0px 77px 3px rgba(0, 0, 0, 0.10); 
  background-color: #fff;
  background-image: url('./assets/image/menu-bg.png');
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.margin-special-users{
  margin-left: 20vw;
  }
  .wrap-users{
  width: 93vw;
  margin-left: 3.5vw;
  display: flex;
  flex-flow: column;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.10); 
  }
  .wrap-user-card{
  border-bottom: 1px solid #ffbd3d;
  padding: 20px 20px;
  width: 90vw;
  }
  .wrap-user-card > p {
    margin-bottom: 10px;
  }
  .wrap-user-card > p > strong{
    font-weight: 600;
  }
  .wrap-user-card > .d-flex > .cloud-table{
    margin-right: 10px;
    display: block;
  }
  .none-pc-filter-arch{
    display: none;
  }
@media (max-width: 768px) {
  .none-pc-filter-arch{
    display: flex;
  }
  .pc-link-menu > img {
    margin-right: 0px;
    width: 30px;
  }
  .mobile-padding > .row .col-md-9{
    max-height: 100%!important;
    height: 100%;
  }
  #glav > .row{
    height: calc(100vh - 130px)!important;
  }
  .auth-wrap{
    height: fit-content!important;
  }
  .mobile-none {
    display: none !important;
  }
  .pc-none {
    display: inherit;
  }
  .mobile-burger {
    border-right: none;
    position: fixed !important;
    top: 0;
    z-index: 999;
    background: transparent;
    box-shadow: none;
    transition: 0.5s ease;
    transform: translateX(-100%);
    padding: 0 !important;
  }
  .mobile-burger.show {
    transform: translateX(0%);
  }
  .mobile-burger > .inner-menu {
    display: flex;
    flex-flow: column;
    padding: 0 15px !important;
    background: #fff;
    height: 65vh;
    margin-top: 0;
    padding-top: 20px !important;
    position: relative;
  }
  .mobile-burger.show > .inner-menu {
    box-shadow: 0 0 240px #000;
  }
  .mobile-logo {
    width: 200px;
    margin: 0 auto;
  }
  .mobile-padding{
    padding-top: 55px;
    padding-bottom: 60px;
    position: relative;
  }
  .mobile-padding > .row{
    height: calc(100vh - 115px);
  }
  .mobile-add-user{
    left: 1%!important;
    width: 98%!important;
  }
  .modal-user-info{
    padding: 20px 15px!important;
  }
  .modal-user-info > .mt-4{
    flex-flow: column!important;
  }
  .modal-user-info > .mt-4 > *{
    margin-bottom: 10px;
  }
  .mobile-roles{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-roles > button, .mobile-roles > a{
    margin: 5px 0px!important;
    width: 48%;
  }
  .mobile-roles > a{
    display: block;
  }
  .input-add-user{
    width: 100%;
  }
  .mobile-menu {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ffbd3d;
    background: #fff;
    align-items: center;
    padding: 0 35px 0 35px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }
  .mini-logo {
    width: 80px;
  }
  .bs-p-zak {
    font-size: 16px;
    line-height: 20px;
  }
  .bs-nomer-zak {
    margin-bottom: 15px;
  }
  .card-order {
    padding-top: 40px;
  }
  .mobile-look {
    padding-top: 15px !important;
  }
  .mobile-flex {
    display: flex;
    justify-content: center;
  }
  .mobile-centered {
    display: flex;
    justify-content: center;
    margin: 10px 0 !important;
  }
  .new-orders-title {
    font-size: 25px;
    margin: 20px 0;
    font-weight: 600;
    text-align: center;
    margin-bottom: -10px !important;
  }
  .bs-a-ul-zak {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    color: #fff;
  }
  .nav-item > a {
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .mobile-burger {
    width: 100vw;
  }
  .auth-wrap {
    height: calc(100vh - 55px);
    padding: 0 15px;
  }
  .bs-input-log {
    width: 100%;
  }
  .bs-p-link{
    margin-top: 20px;
  }
}
</style>
