import store from './store';
import config from './config';
import router from './router';
const api = config.API_URL;

const jwt = ()=> store.state.userInfo.jwt;

export default {
  async fetch(ref, {method, body}){
      var res = await fetch(api+ref, {
        method,
        headers: {
          'Content-Type': "application/json",
          Authorization: `Bearer ${jwt()}`
        },
        body: JSON.stringify(body)
      });
      if(res.status == 403){
        store.commit('setUserInfo', null);
        router.push({name: 'loggin'});
      }
      var resData = await res.json();
      if(resData.success){
        return resData.data;
      }else{
        throw resData;
      }
  },
  get(ref){
    return this.fetch(ref, {method:'GET'});
  },
  post(ref, body){
    return this.fetch(ref, {method:'POST', body});
  },
  patch(ref, body){
    return this.fetch(ref, {method:'PATCH', body});
  },
  put(ref, body){
    return this.fetch(ref, {method:'PUT', body});
  },
  delete(ref){
    return this.fetch(ref, {method:'DELETE'});
  },
}