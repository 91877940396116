import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router';
import routes from './routes';
import store from "../store";
console.log("####", createRouter)
//Vue.use(VueRouter)

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.path == '/dashboard')
    next('/orders');
  else next();
})
router.beforeEach((to, from, next) => {
   if(store.state.userInfo == null && to.path != '/loggin')
     next('/loggin');
   else next();
})


export default router;
